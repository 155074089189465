body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.p-menuitem-link:focus {
  outline: none !important;
}

@media screen and (max-width: 640px) {
  .p-steps {
    height: 350px;
  }
  .p-steps > ul {
    flex-direction: column;
    height: 100%;
  }
  .p-steps > ul .p-steps-item {
    flex-direction: column-reverse;
    align-items: flex-start;
    justify-content: center;
  }
  .p-steps > ul .p-steps-item:before {
    position: static;
    left: auto;
    top: auto;
    margin-top: 0;
    border-left: 1px solid var(--surface-d);
    border-top: 0 none;
    width: auto;
    height: 100%;
    margin-left: 1rem;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link {
    flex-direction: row;
    overflow: visible;
  }
  .p-steps > ul .p-steps-item .p-menuitem-link .p-steps-title {
    margin: 0 0.5rem 0;
  }
  .p-steps > ul .p-steps-item:last-child {
    flex-grow: 0;
  }
  .p-steps > ul .p-steps-item:last-child .p-menuitem-link {
    padding: 0;
  }
  .p-steps > ul .p-steps-item:last-child:before {
    display: none;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
